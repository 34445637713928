<template>
  <ValidationObserver ref="form7">
    <form>
      <v-row>
        <v-col cols="12">
          <h3 class="headline">CONTRATOS CON TERCEROS</h3>
          <h4>
            Si dispone de estos servicios indique la razón social, el email, el
            cif y la dirección
          </h4>
        </v-col>
      </v-row>

      <v-row row wrap>
        <v-col cols="12">
          <v-card dark :color="colores.primario">
            <v-card-text class="white--text  px-2 font-weight-bold"
              >Responde</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>

      <!--hecho -->

      <v-row v-for="(pregunta, index) in preguntas.pregunta" :key="index">
        <!--fiscal -->
        <v-col cols="12" v-if="preguntas.pregunta[index].id == 1">
          <v-row wrap v-if="preguntas.pregunta[index].id == 1">
            <v-col cols="12" class="mb-5">
              <v-row>
                <v-col cols="12">
                  <v-card :color="colores.secundario" class="justify-center">
                    <v-card-text
                      class="d-flex  justify-center align-center px-2 font-weight-bold"
                      >{{ pregunta.pregunta }}</v-card-text
                    >
                  </v-card>
                </v-col>
                <v-col cols="12" class="d-flex justify-space-around">
                  <v-btn fab @click="preguntas.datos[index].activo = 1">
                    <v-icon
                      large
                      :color="
                        preguntas.datos[index].activo == 1
                          ? 'green darken-3'
                          : 'grey'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn>
                  <v-btn fab @click="preguntas.datos[index].activo = 0">
                    <v-icon
                      large
                      :color="
                        preguntas.datos[index].activo == 0 ? 'error' : 'grey'
                      "
                      >cancel</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" v-if="preguntas.datos[index].activo">
              <v-row>
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].rSocial"
                      label="Razón Social *"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required|nif_cif_nie"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].cif"
                      label="Cif/Nif"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].email"
                      label="Email"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].dir"
                      label="Dirección"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="min_value:01000|max_value:52999|required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].codPostal"
                      label="Código postal"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].pob"
                      label="población"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <!--laboral -->
        <v-col cols="12">
          <v-row wrap v-if="preguntas.pregunta[index].id == 2">
            <v-col cols="12">
              <v-card :color="colores.secundario" class="justify-center">
                <v-card-text
                  class="d-flex  justify-center align-center px-2 font-weight-bold"
                  >{{ pregunta.pregunta }}</v-card-text
                >
              </v-card>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-around">
              <v-btn fab @click="preguntas.datos[index].activo = 1">
                <v-icon
                  large
                  :color="
                    preguntas.datos[index].activo == 1
                      ? 'green darken-3'
                      : 'grey'
                  "
                  >check_circle</v-icon
                >
              </v-btn>
              <v-btn fab @click="preguntas.datos[index].activo = 0">
                <v-icon
                  large
                  :color="preguntas.datos[index].activo == 0 ? 'error' : 'grey'"
                  >cancel</v-icon
                >
              </v-btn>
            </v-col>
            <v-row v-if="preguntas.datos[index].activo == 1">
              <template v-if="preguntas.datos[0].activo == 1">
                <v-col cols="12">
                  <v-card :color="colores.secundario" class="justify-center">
                    <v-card-text
                      class="d-flex  justify-center align-center px-2 font-weight-bold"
                      >La asesoría laboral me la lleva mi asesor
                      fiscal</v-card-text
                    >
                  </v-card>
                </v-col>
                <v-col cols="12" class="d-flex justify-space-around">
                  <v-btn fab @click="preguntas.datos[1].AsesoriasIguales = 1">
                    <v-icon
                      large
                      :color="
                        preguntas.datos[1].AsesoriasIguales == 1
                          ? 'green darken-3'
                          : 'grey'
                      "
                      >check_circle</v-icon
                    >
                  </v-btn>
                  <v-btn fab @click="preguntas.datos[1].AsesoriasIguales = 0">
                    <v-icon
                      large
                      :color="
                        preguntas.datos[1].AsesoriasIguales == 0
                          ? 'error'
                          : 'grey'
                      "
                      >cancel</v-icon
                    >
                  </v-btn>
                </v-col>
              </template>
            </v-row>

            <template
              wrap
              v-if="
                preguntas.datos[1].AsesoriasIguales == 0 ||
                  preguntas.datos[0].activo == 0
              "
            >
              <v-col cols="12" v-if="preguntas.datos[index].activo">
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].rSocial"
                      label="Razón Social *"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required|nif_cif_nie"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].cif"
                      label="Cif/Nif"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].email"
                      label="Email"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].dir"
                      label="Dirección"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="min_value:01000|max_value:52999|required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].codPostal"
                      label="Código postal"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      :error-messages="errors[0]"
                      v-model="preguntas.datos[index].pob"
                      label="población"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-divider></v-divider>
              </v-col>
            </template>
          </v-row>
        </v-col>

        <!-- normal -->

        <v-row wrap v-if="pregunta.id != 1 && pregunta.id != 2">
          <v-col cols="12">
            <v-card :color="colores.secundario" class="justify-center">
              <v-card-text
                class="d-flex  justify-center align-center px-2 font-weight-bold"
                >{{ pregunta.pregunta }}</v-card-text
              >
            </v-card>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-around">
            <v-btn fab @click="preguntas.datos[index].activo = 1">
              <v-icon
                large
                :color="
                  preguntas.datos[index].activo == 1 ? 'green darken-3' : 'grey'
                "
                >check_circle</v-icon
              >
            </v-btn>
            <v-btn fab @click="preguntas.datos[index].activo = 0">
              <v-icon
                large
                :color="preguntas.datos[index].activo == 0 ? 'error' : 'grey'"
                >cancel</v-icon
              >
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="preguntas.datos[index].activo">
            <v-col cols="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="preguntas.datos[index].rSocial"
                  label="Razón Social *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider
                rules="required|nif_cif_nie"
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="preguntas.datos[index].cif"
                  label="Cif/Nif"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider rules="required|email" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="preguntas.datos[index].email"
                  label="Email"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="preguntas.datos[index].dir"
                  label="Dirección"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider
                rules="min_value:01000|max_value:52999|required"
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="preguntas.datos[index].codPostal"
                  label="Código postal"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="preguntas.datos[index].pob"
                  label="población"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-row>

      <v-row class="d-flex justify-center align-center">
        <v-col cols="12">
          <v-card :color="colores.secundario" class="justify-center">
            <v-card-text
              class="d-flex  justify-center align-center px-2 font-weight-bold"
              >Otros</v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-space-around">
          <v-btn fab @click="otros.activo = 1">
            <v-icon large :color="otros.activo == 1 ? 'green darken-3' : 'grey'"
              >check_circle</v-icon
            >
          </v-btn>
          <v-btn fab @click="otros.activo = 0">
            <v-icon large :color="otros.activo == 0 ? 'error' : 'grey'"
              >cancel</v-icon
            >
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="otros.activo">
          <v-row
            cols="12"
            v-for="(otro, index) in otros.otrosDatos"
            :key="index"
          >
            <v-col cols="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="otro.Actividad"
                  label="Actividad"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="otro.rSocial"
                  label="Razón Social *"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider
                rules="required|nif_cif_nie"
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors[0]"
                  name="Cif/Nif"
                  v-model="otro.cif"
                  label="Cif/Nif"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider rules="required|email" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="otro.email"
                  label="Email"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="otro.dir"
                  label="Dirección"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider
                rules="min_value:01000|max_value:52999|required"
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="otro.codPostal"
                  label="Código postal"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  :error-messages="errors[0]"
                  v-model="otro.pob"
                  label="población"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="6" class="d-flex justify-center">
              <v-divider> </v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="otros.activo">
          <v-row class="d-flex justify-center">
            <v-col cols="6" v-if="otros.otrosDatos.length > 1">
              <v-btn fab color="amber darken-3" dark @click="añadirOtros()">
                <v-icon large>add</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="otros.otrosDatos.length == 1">
              <v-btn fab color="amber darken-3" dark @click="añadirOtros()">
                <v-icon large>add</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                fab
                color="error"
                v-if="otros.otrosDatos.length > 1"
                dark
                @click="quitarOtros()"
              >
                <v-icon large>remove</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-alert
        :value="error"
        transition="scale-transition"
        type="error"
        outlined
        >Responde a todas las preguntas para continuar!</v-alert
      >

      <navButtons
        :page="page"
        :colores="colores"
        v-on:stepper="$emit('stepper', $event)"
        v-on:GC="uploadForm('C')"
        v-on:GA="uploadForm('A')"
      ></navButtons>

      <!-- <template v-if="ver == false">
      <v-btn color="error" type="button" @click="$emit('stepper','6')">Atras</v-btn>
              <v-btn color="teal darken-1" dark type="button" @click="validar('form-7',6)">Guardar y Atras</v-btn>

      <v-btn :color="colores.primario" dark type="submit">Guardar y continuar</v-btn>
        </template>
        <template v-else>
<v-btn color="error" type="button" @click="$emit('stepper','6')">Atras</v-btn>
        <v-btn color="teal darken-1" dark type="button" @click="$emit('stepper','8')">Siguiente</v-btn>

        </template> -->
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ["token", "url", "paso7", "idFormulario", "guardar", "ver", "colores"],
  data: () => ({
    page: 7,
    preguntas: {
      pregunta: [
        {
          id: 1,
          pregunta: "Asesoría fiscal – contable",
        },
        {
          id: 2,
          pregunta: "Asesoría laboral",
        },
        {
          id: 3,
          pregunta: "Mantenimiento Web",
        },
        {
          id: 4,
          pregunta: "Mantenimiento Informático",
        },
        {
          id: 5,
          pregunta: "Hosting, alojamiento de la web",
        },
        {
          id: 6,
          pregunta: "Prevencion de Riesgos Laborales",
        },
        {
          id: 7,
          pregunta: "Destrucción de documentos",
        },
        {
          id: 8,
          pregunta: "Videovigilancia",
        },
        {
          id: 9,
          pregunta: "Limpieza oficinas",
        },
      ],

      datos: [
        {
          id: 1,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
        {
          id: 2,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
          AsesoriasIguales: null,
        },
        {
          id: 3,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
        {
          id: 4,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
        {
          id: 5,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
        {
          id: 6,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
        {
          id: 7,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
        {
          id: 8,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
        {
          id: 9,
          activo: null,
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
      ],
    },
    otros: {
      activo: false,
      otrosDatos: [
        {
          Actividad: "",
          rSocial: "",
          cif: "",
          email: "",
          dir: "",
          codPostal: "",
          pob: "",
        },
      ],
    },

    error: false,
  }),
  methods: {
    asesoriasCopia() {
      if (
        this.preguntas.datos[0].activo == 1 &&
        this.preguntas.datos[1].AsesoriasIguales == null
      ) {
        this.error = true;
        return false;
      }
      if (this.preguntas.datos[1].AsesoriasIguales == 1) {
        this.preguntas.datos[1] = JSON.parse(
          JSON.stringify(this.preguntas.datos[0])
        );
        this.preguntas.datos[1].id = 2;
        this.preguntas.datos[1].AsesoriasIguales = 1;
      }
    },

    async validateForm() {
      const isValid = await this.$refs.form7.validate();
      if (!isValid) return false;
      for(const pregunta of this.preguntas.datos) {
        if (pregunta.activo == null) return false;
      }
      return true;
      // this.$validator.validateAll(scope).then((result) => {
      //   if (result) {
      //     for (let index = 0; index < this.preguntas.datos.length; index++) {
      //       const pregunta = this.preguntas.datos[index];

      //       if (pregunta.activo == null) {
      //         this.error = true;
      //         return;
      //       } else {
      //         preguntaC++;
      //       }

      //       if (preguntaC == 9) {
      //         this.asesoriasCopia();

      //         Vue.axios
      //           .post(me.url + "paso7", {
      //             preguntas: me.preguntas.datos,
      //             id: me.idFormulario,
      //             otros: me.otros,
      //             token: me.token,
      //           })
      //           .then(function() {
      //             me.error = false;
      //             me.$emit("stepper", aXPaso);
      //           })
      //           .catch(function(error) {
      //             console.log(error);
      //           });
      //       }
      //     }
      //   } else {
      //     this.error = true;
      //   }
      // });
    },
    async uploadForm(type) {
      const isValid = await this.validateForm();
      if (!isValid) {
        this.error = true
        return false}
      this.asesoriasCopia();
      try {
        await this.axios.post(this.url + "paso7", {
          preguntas: this.preguntas.datos,
          id: this.idFormulario,
          otros: this.otros,
          token: this.token,
        });
        if (type === "C") {
          this.$emit("stepper", this.page + 1);
        }
        if (type === "A") {
          this.$emit("stepper", this.page - 1);
        }
        return true;
      } catch (error) {
        console.log(error);
        this.error = true;
        return false;
      }
    },
    añadirOtros() {
      this.otros.otrosDatos.push({
        Actividad: "",
        rSocial: "",
        cif: "",
        email: "",
      });
    },

    quitarOtros() {
      this.otros.otrosDatos.splice(-1, 1);
    },
  },
  computed: {
    asesoria() {
      return this.preguntas.datos[1].activo;
    },
  },
  watch: {
    asesoria(asesoria) {
      if (asesoria == 0) this.preguntas.datos[1].AsesoriasIguales = 0;
    },
    error() {
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    paso7(datos) {
      this.otros = datos[1];
      this.preguntas.datos = datos[0];
    },
    // guardar(val) {
    //   if (val == true) {
    //     this.validar("form-7");
    //   }
    // },
  },
};
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-row row wrap>
        <v-col cols="12" class="d-flex justify-center align-center">
          <h1>Completado</h1>
        </v-col>

        <v-col cols="12" class="d-flex justify-center align-center">
          <v-checkbox
            v-model="acepta"
            label="¿Aceptas los terminos y condiciones para enviar este formulario y cerrar su edición?"
            color="success"
            value="true"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-alert
            :value="error"
            transition="scale-transition"
            type="error"
            outlined
            >Debes marcar la casilla para finalizar. !</v-alert
          >
        </v-col>
      </v-row>

      <v-row
        ><v-col cols="6" class="d-flex justify-center align-center">
          <v-btn color="error" type="button" @click="$emit('stepper', '8')"
            >Atras</v-btn
          > </v-col
        ><v-col cols="6">
          <v-btn
            :color="colores.primario"
            dark
            type="button"
            @click="completar()"
            >Finalizar</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Swal from "sweetalert2";

export default {
  props: ["token", "url", "idFormulario", "guardar", "path", "ver", "colores"],
  data() {
    return {
      page: 9,
      acepta: false,
      error: false,
    };
  },
  methods: {
    completar() {
      let me = this
      if (!this.acepta) {
        this.error = true;
        return false;
      }
      try {
        this.axios
          .put(me.url + "datos/cambiarestado", {
            token: me.token,
            id: me.idFormulario,
            estado: 1,
          })
          .then(function() {
            Swal.fire({
              type: "success",
              title: "Enviado a revisión",
              text: "",
            });
            setTimeout(function() {
              location = me.path;
            }, 5000);
          });
      } catch (error) {
        this.error = true;
        console.log(error);
        return false;
      }
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
  },
};
</script>
